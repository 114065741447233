import React from 'react'
import {
  Row,
  Col,
  Gap,
  Text,
  SEO,
  Title,
  Container,
  PriceCalc,
  PriceEquation,
  FaqSection,
  ArticlesSection,
  Sup,
  Tooltip,
  PriceCompareSection,
} from '../components'
import { PriceGraph } from '../components/graphs'

import glassesSrc from '../data/images/glasses.png'

class Page extends React.Component {
  render() {
    const {
      location: { pathname },
    } = this.props

    return (
      <>
        <SEO pathname={pathname} title="Cena vody" />
        <Gap.Top />
        <Container>
          <Col width="100%" justifyContent="center" alignItems="center">
            <Title.Main>Cena vody</Title.Main>
          </Col>
          <Gap gap="185px" />

          <Col
            width="100%"
            alignItems="stretch"
            mobileAlignItems="center"
            bp="tablet"
          >
            <Row justifyContent="space-between" responsive bp="tablet">
              <Col.Text narrow>
                <Title.Sub>Kolik stojí voda?</Title.Sub>
              </Col.Text>

              <Gap.Col showAfter="tablet" />
              <Gap.Title showBelow="tablet" />

              <Col.Text narrow>
                <Text>
                  V roce 1994 přestal stát dotovat náklady na provoz vodáren a
                  cena vody se tak znatelně navýšila. Z celkových nákladů na
                  bydlení a energie jde ale stále o nejmenší položku.
                </Text>
                <Gap.Paragraph />
                <Text>
                  Jeden kubík (m<Sup>3</Sup>) stojí průměrně 85,1 Kč.{' '}
                  <Tooltip content="Jeden litr je tisícina jednoho kubíku.">
                    Jeden litr (dm<Sup>3</Sup>)
                  </Tooltip>{' '}
                  tak vyjde zhruba na 9 haléřů.
                </Text>
              </Col.Text>
            </Row>

            <Gap gap="150px" mobileGap="30px" bp="tablet" />

            <Row justifyContent="space-between" responsive bp="tablet">
              <Col.Text narrow>
                <Gap gap="30px" />
                <Title.Sub>
                  Cena jedné
                  <strong> sklenice balené vody je průměrně 90× dražší </strong>
                  než sklenice kohoutkové vody.
                </Title.Sub>
                <Gap.Title />
                <Text>
                  PET lahve balených vod navíc představují zátěž pro životní
                  prostředí.
                </Text>
              </Col.Text>
              <Gap.Col />
              <Col.Text narrow>
                <img
                  src={glassesSrc}
                  alt="sklenice vody"
                  style={{ maxWidth: '100%' }}
                />
              </Col.Text>
            </Row>

            <Gap gap="140px" mobileGap="60px" bp="tablet" />

            <Row justifyContent="space-between" responsive bp="tablet">
              <Col.Text narrow>
                <Title.Sub>
                  Vypočítejte si průměrnou úhradu za vodu pro svou domácnost.
                </Title.Sub>
              </Col.Text>

              <Gap.Col showAfter="tablet" />
              <Gap.Title showBelow="tablet" />

              <Col.Text narrow>
                <Text>
                  Celkový účet za vodu se skládá z vaší reálné spotřeby násobené
                  sazbou tzv. vodného a stočného za dané období.
                </Text>
                <Gap.Paragraph />
                <Text>
                  <strong>Vodné </strong>
                  značí cenu za vodu z veřejné vodovodní sítě – přesněji za její
                  výrobu a dodání. Může být jednosložková (platíte pouze za
                  odebranou vodu – v její ceně už jsou všechny náklady
                  zahrnuty), nebo dvousložková (platba se skládá z úhrady za
                  odebranou vodu a pevně dané částky, často související s
                  velikostí vodoměru).
                  <strong> Stočné </strong>
                  je úhrada za odvedení vody kanalizací a její čištění.
                </Text>
              </Col.Text>
            </Row>
          </Col>
        </Container>

        <Gap gap="90px" mobileGap="60px" bp="tablet" />

        <PriceCalc />

        <Container>
          <Gap gap="140px" mobileGap="70px" bp="tablet" />

          <PriceCompareSection />

          <Gap gap="140px" mobileGap="70px" bp="tablet" />

          <Col
            width="100%"
            alignItems="stretch"
            mobileAlignItems="center"
            bp="tablet"
          >
            <Row justifyContent="space-between" responsive bp="tablet">
              <Col.Text narrow>
                <Title.Sub>
                  Proč
                  <strong> není cena vody stejná </strong>
                  na celém území ČR?
                </Title.Sub>
                <Gap.Title />
                <Text>
                  Největší podíl na cenových rozdílech mají specifika terénních
                  podmínek, kvalitu surové vody v oblasti nebo stav a velikost
                  infrastruktury. Vliv má také počet připojených domácností či
                  podnikatelů a velikost jejich odběrů.
                </Text>
              </Col.Text>
              <Gap.Col />
              <Col.Text narrow>
                <Gap gap="110px" mobileGap="20px" bp="tablet" />
                <Title.Sub>Jak se stanovuje cena vody?</Title.Sub>
                <Gap.Title />
                <Text>
                  Vodárenství reguluje zejména Ministerstvo financí a
                  Ministerstvo zemědělství. Pro stanovení ceny vody jsou závazné
                  předpisy, které vydává Ministerstvo financí pro každý
                  kalendářní rok, a další relevantní právní předpisy pro obor
                  vodárenství.
                </Text>
                <Gap.Paragraph />
                <Text>
                  Největší část ceny běžně tvoří náklady spojené s opravami a
                  nájmem infrastrukturního majetku. Další podstatnou položkou
                  ceny jsou mzdové náklady nebo náklady na surovou vodu a pitnou
                  vodu předanou.
                </Text>
                <Gap gap="40px" />
                <PriceEquation />
              </Col.Text>
            </Row>
          </Col>

          <Gap gap="111px" mobileGap="80px" bp="tablet" id="price-graph" />
          <Col alignItems="center" width="100%">
            <Title.Sub>Srovnání vývoje ceny vody v krajích ČR (bez DPH) </Title.Sub>
            <Gap gap="40px " />
          </Col>
          <PriceGraph />

          <Gap gap="140px" mobileGap="100px" bp="tablet" />
        </Container>

        <ArticlesSection page="PRICE" />

        <FaqSection page="PRICE" />

        <Gap gap="230px" />
      </>
    )
  }
}

export default Page
